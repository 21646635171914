<template>
  <div
    class="border-t flex cursor-pointer border-neutral-50 px-3 transition-all duration-200 hover:bg-neutral-0 md:px-6"
  >
    <div
      class="flex-grow w-4/12 flex-col justify-center p-2 py-4 pr-4"
      @click="emitClick"
    >
      <slot name="colOne"></slot>
    </div>
    <div
      class="hidden w-3/12 flex-col justify-center p-2 py-4 pr-4 md:flex"
      @click="emitClick"
    >
      <slot name="colTwo"></slot>
    </div>
    <div
      class="hidden w-2/12 flex-col justify-center p-2 py-4 pr-4 lg:flex"
      @click="emitClick"
    >
      <slot name="colThree"></slot>
    </div>
    <div
      class="hidden w-2/12 flex-col justify-center p-2 py-4 pr-4 lg:flex"
      @click="emitClick"
    >
      <slot name="colFour"></slot>
    </div>
    <div class="flex w-1/12 flex-col justify-center py-4">
      <div class="flex justify-end pr-2">
        <slot name="colFive"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    emitClick() {
      this.$emit("emitClick");
    },
  },
};
</script>

<template>
  <TableRowFrame @emitClick="open">
    <template v-slot:colOne>
      <div v-if="user.firstName" class="flex items-center">
        <UserAvatar variant="primary" :user="user" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            {{ `${user.firstName} ${user.lastName}` }}
          </div>
          <div class="truncate text-neutral-400">
            {{ user.email }}
          </div>
        </div>
      </div>
      <div v-else class="flex items-center">
        <UserAvatar variant="danger" :user="user" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium">
            {{ user.email }}
          </div>
          <div class="truncate text-danger-400">Complete user details</div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div class="truncate flex items-center text-sm">
        {{ user.organisation ? user.organisation.name : "" }}
      </div>
    </template>
    <template v-slot:colThree>
      <div class="text-sm">
        {{ user.role.description || user.role.name }}
      </div>
    </template>
    <template v-slot:colFour>
      <div class="text-sm text-neutral-400">{{ since() }}</div>
    </template>
    <template v-slot:colFive>
      <OverflowMenu class="flex items-center" :items="menuItems" />
    </template>
  </TableRowFrame>
</template>

<script>
import UserAvatar from "@/components/common/UserAvatar";
import TableRowFrame from "@/components/user-management/TableRowFrame";
import OverflowMenu from "@/components/common/OverflowMenu";

export default {
  components: {
    OverflowMenu,
    UserAvatar,
    TableRowFrame,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          name: this.$t("global.viewDetails"),
          showIf: true,
          callback: () => {
            this.open();
          },
        },
      ],
    };
  },

  methods: {
    open() {
      this.$emit("open", this.user);
    },
    since() {
      const date = new Date(parseInt(this.user.createdAt));
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
  },
};
</script>

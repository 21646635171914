<template>
  <TableLegendFrame>
    <template v-slot:colOne>
      {{ $t("global.name") }}
    </template>
    <template v-slot:colTwo>
      {{ $t("global.organisation") }}
    </template>
    <template v-slot:colThree>
      {{ $t("global.role") }}
    </template>
    <template v-slot:colFour>
      {{ $t("global.since") }}
    </template>
  </TableLegendFrame>
</template>

<script>
import TableLegendFrame from "@/components/user-management/TableLegendFrame";

export default {
  components: {
    TableLegendFrame,
  },
};
</script>

<template>
  <ModalSide @close="closeModal">
    <template v-slot:tabs>
      <button
        v-if="!editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ $t("components.userManagement.users.userDetails") }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
      <button
        v-if="editMode"
        class="border-r focus:outline-none transition relative h-full border-neutral-50 px-8 text-xs font-medium text-neutral-900 duration-200 hover:text-neutral-900"
      >
        <span>
          {{ $t("components.userManagement.users.editUser") }}
        </span>
        <span class="absolute inset-0 border-b-2 border-primary-500"></span>
      </button>
    </template>

    <template v-slot:content>
      <ModalSideContent v-if="!editMode" class="flex-grow">
        <div class="mb-4 flex items-start justify-end">
          <div class="flex w-1/2 justify-center">
            <UserAvatar variant="primary" size="xl" :user="user" />
          </div>
          <div class="flex w-1/4 justify-end">
            <BaseButton
              size="medium"
              variant="outline"
              @buttonClick="openEditMode"
            >
              <template #iconCenter>
                <IconEdit class="text-neutral-300" />
              </template>
            </BaseButton>
          </div>
        </div>
        <div class="mb-8 text-center">
          <div class="text-lg-relaxed font-medium">
            {{ user.firstName + " " + user.lastName }}
          </div>
          <div class="text-sm-relaxed text-neutral-400">
            {{ user.email }}
          </div>
        </div>
        <div class="mb-2 flex">
          <div class="border-r w-1/2 border-neutral-50 text-center">
            <div class="text-xs-relaxed font-medium uppercase text-neutral-400">
              {{ $t("components.userManagement.users.role") }}
            </div>
            <div class="text-sm-relaxed">
              {{ user.role.description || user.role.name }}
            </div>
          </div>
          <div v-if="user.organisation" class="w-1/2 text-center">
            <div class="text-xs-relaxed font-medium uppercase text-neutral-400">
              {{ $t("components.userManagement.users.organisation") }}
            </div>
            <div class="text-sm-relaxed">{{ user.organisation.name }}</div>
          </div>
        </div>
      </ModalSideContent>

      <ModalSideContent v-if="!editMode">
        <div class="text-xs text-neutral-400">
          {{ $t("components.userManagement.users.createdAt") }} {{ since() }}
        </div>
      </ModalSideContent>

      <ModalSideContent
        v-if="editMode"
        class="flex-grow grid gap-6 overflow-y-scroll"
      >
        <div>
          <UsersForm
            :current-user="user"
            :organisations="organisations"
            @updateFirstName="updateFirstName"
            @updateLastName="updateLastName"
            @updateOrganisationUuid="updateOrganisationUuid"
          />
          <div
            v-if="formError"
            class="bg-danger-0 rounded-sm border-l-2 border-danger-100 px-3 py-2 text-xs font-medium text-danger-500"
          >
            {{ formError }}
          </div>
        </div>
      </ModalSideContent>
      <ModalSideActions v-if="editMode" class="justify-end">
        <BaseButton
          :text="$t(`global.cancel`)"
          size="medium"
          variant="outline"
          @buttonClick="closeModal"
        ></BaseButton>

        <BaseButton
          :text="$t(`global.saveChanges`)"
          size="medium"
          variant="fill"
          class="ml-2"
          @buttonClick="submit"
        >
        </BaseButton>
      </ModalSideActions>
    </template>
  </ModalSide>
</template>

<script>
import ModalSide from "@/components/common/ModalSide";
import ModalSideContent from "@/components/common/ModalSideContent";
import ModalSideActions from "@/components/common/ModalSideActions";

import UserAvatar from "@/components/common/UserAvatar";
import UsersForm from "@/components/user-management/users/UsersForm";

import UPDATE_USER from "@/graphql/UpdateUser.gql";

export default {
  components: {
    ModalSide,
    ModalSideContent,
    ModalSideActions,
    UserAvatar,
    UsersForm,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
    organisations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      formError: null,
      editMode: false,
      editedFirstName: this.user.firstName,
      editedLastName: this.user.lastName,
      editedOrganisationUuid: this.organisationName(),
    };
  },

  created() {
    if (!this.user.firstName || !this.user.lastName) {
      this.editMode = true;
    }
  },

  methods: {
    organisationName() {
      this.user.organisation ? this.user.organisation.name : null;
    },
    closeModal() {
      this.$emit("close");
    },
    openEditMode() {
      this.editMode = true;
    },
    closeEditMode() {
      this.editMode = false;
      this.formError = null;
    },
    since() {
      const date = new Date(parseInt(this.user.createdAt));
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    },
    updateFirstName(firstName) {
      this.editedFirstName = firstName;
    },
    updateLastName(lastName) {
      this.editedLastName = lastName;
    },
    updateOrganisationUuid(OrganisationUuid) {
      this.editedOrganisationUuid = OrganisationUuid;
    },
    submit() {
      this.$apollo
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            uuid: this.user.uuid,
            firstName: this.editedFirstName,
            lastName: this.editedLastName,
            organisationUuid: this.editedOrganisationUuid,
          },
        })
        .then(() => {
          this.closeModal();
        })
        .catch((error) => {
          this.formError = JSON.stringify(error.message);
        });
    },
  },
};
</script>

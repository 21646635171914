<template>
  <div class="grid gap-6">
    <div class="md:-mx-2 md:flex">
      <div class="w-full md:px-2">
        <TextField
          v-model="firstName"
          :label="translations.firstNameLabel"
          :placeholder="translations.firstNamePlaceholder"
          :focus="firstName === null"
          @input="updateFirstName"
        />
      </div>
      <div class="w-full md:px-2">
        <TextField
          v-model="lastName"
          :label="translations.lastNameLabel"
          :placeholder="translations.lastNamePlaceholder"
          @input="updateLastName"
        />
      </div>
    </div>
    <TextField
      v-model="email"
      :label="translations.emailLabel"
      :placeholder="translations.emailPlaceholder"
      :disabled="true"
    />
    <SelectField
      v-model="organisationUuid"
      :label="translations.partOfOrganisationLabel"
      @input="updateOrganisationUuid"
    >
      <option value="-1" selected disabled>
        {{ translations.partOfOrganisationPlaceholder }}
      </option>
      <option value="">
        {{ translations.partOfOrganisationNullValue }}
      </option>
      <option
        v-for="organisation in organisations"
        :key="organisation.uuid"
        :value="organisation.uuid"
        :selected="organisationUuid === organisation.uuid"
      >
        {{ organisation.name }}
      </option>
    </SelectField>
  </div>
</template>

<script>
export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {
          firstName: null,
          lastName: null,
          email: null,
          organisationUuid: -1,
        };
      },
    },
    organisations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      autofocusFirstName: false,
      firstName: this.currentUser.firstName,
      lastName: this.currentUser.lastName,
      email: this.currentUser.email,
      organisationUuid: this.currentUser.organisation
        ? this.currentUser.organisation.uuid
        : null,
      translations: {
        firstNameLabel: this.$t(
          "components.userManagement.users.usersForm.firstName",
        ),
        firstNamePlaceholder: this.$t(
          "components.userManagement.users.usersForm.firstNamePlaceholder",
        ),
        lastNameLabel: this.$t(
          "components.userManagement.users.usersForm.lastName",
        ),
        lastNamePlaceholder: this.$t(
          "components.userManagement.users.usersForm.lastNamePlaceholder",
        ),
        emailLabel: this.$t("components.userManagement.users.usersForm.email"),
        emailPlaceholder: this.$t(
          "components.userManagement.users.usersForm.emailPlaceholder",
        ),
        partOfOrganisationLabel: this.$t(
          "components.userManagement.users.usersForm.partOfOrganisation",
        ),
        partOfOrganisationPlaceholder: this.$t(
          "components.userManagement.users.usersForm.partOfOrganisationPlaceholder",
        ),
        partOfOrganisationNullValue: this.$t(
          "components.userManagement.users.usersForm.partOfOrganisationNullValue",
        ),
      },
    };
  },
  methods: {
    updateFirstName() {
      this.$emit("updateFirstName", this.firstName);
    },
    updateLastName() {
      this.$emit("updateLastName", this.lastName);
    },
    updateOrganisationUuid() {
      this.$emit("updateOrganisationUuid", this.organisationUuid);
    },
  },
};
</script>

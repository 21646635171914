<template>
  <div class="sm:container">
    <div v-if="error">
      {{ error }}
    </div>
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("pages.manageUsers.title") }}
        </template>
        <template v-slot:description>
          {{ $t("pages.manageUsers.description") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            size="medium"
            variant="outline"
            :text="$t(`pages.manageUsers.createUserInAuth0`)"
            @buttonClick="redirectToAuth0"
          >
            <template #iconLeft>
              <IconAuth0 class="text-neutral-300" />
            </template>
          </BaseButton>
        </template>
      </TableHeader>
      <UsersTableLegend />
      <UsersTableRow
        v-for="user in users"
        :key="user.uuid"
        :user="user"
        @open="openModalDetailsUsers"
      />
    </TableFrame>
    <transition name="fade">
      <UsersModalDetails
        v-if="modalUsersDetailsOpen"
        :user="userDetails"
        :organisations="organisations"
        @close="closeModal"
      />
    </transition>
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import UsersTableLegend from "@/components/user-management/users/UsersTableLegend";
import UsersTableRow from "@/components/user-management/users/UsersTableRow";
import UsersModalDetails from "@/components/user-management/users/UsersModalDetails";

import FIND_USERS from "@/graphql/FindUsers.gql";
import FIND_ORGANISATION_OPTIONS from "@/graphql/FindOrganisationOptions.gql";

export default {
  name: "ManageAllUsers",
  components: {
    TableFrame,
    TableHeader,
    UsersTableLegend,
    UsersTableRow,
    UsersModalDetails,
  },

  data() {
    return {
      error: null,
      users: [],
      organisationOptions: null,
      modalUsersDetailsOpen: false,
      userDetails: "",
    };
  },

  apollo: {
    users: {
      query: FIND_USERS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
    organisations: {
      query: FIND_ORGANISATION_OPTIONS,
      error(error) {
        this.error = JSON.stringify(error.message);
      },
    },
  },

  methods: {
    redirectToAuth0() {
      window.open("https://manage.auth0.com/", "_blank");
    },
    openModalDetailsUsers(user) {
      this.userDetails = user;
      this.modalUsersDetailsOpen = true;
      document.body.classList.add("overflow-y-hidden");
    },
    closeModal() {
      this.modalUsersDetailsOpen = false;
      document.body.classList.remove("overflow-y-hidden");
    },
  },
};
</script>
